import { Box, Card, CardContent, CardMedia, Link, makeStyles, Typography } from "@material-ui/core";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import theme from "../../theme/main-theme";
import i18n from "../../services/i18n";
import WalletLink from "../../services/Organizations/WalletLink";
import { NavigationType } from "../../services/Organizations/NavigationType";
import arrowIcon from "../../assets/images/arrow.svg";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#ccc",
    position: "relative",
    height: 354,
    width: 325,
    marginBottom: 60,
    borderRadius: 30,
    boxShadow: "none",
    "&:hover": {
      "& .MuiCardMedia-root": {
        filter: "brightness(50%)",
        transition: "filter 0.300s cubic-bezier(0.4, 0, 0.2, 1)",
      },
      "& .MuiBox-root": {
        height: 48,
        width: 48,
        transition: "height 0.300s cubic-bezier(0.4, 0, 0.2, 1), width 0.300s cubic-bezier(0.4, 0, 0.2, 1)",
      },
      "& .MuiBox-root img": {
        height: 16.5,
        width: 16.5,
        transition: "height 0.300s cubic-bezier(0.4, 0, 0.2, 1), width 0.300s cubic-bezier(0.4, 0, 0.2, 1)",
      },
    },
  },
  content: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
    padding: "2rem",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: "-1px",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 64,
    width: 64,
    backgroundColor: theme.palette.success.main,
    borderRadius: "50%",
    transition: "height 0.300s cubic-bezier(0.0, 0, 0.2, 1), width 0.300s cubic-bezier(0.0, 0, 0.2, 1)",
    "& img": {
      transition: "height 0.300s cubic-bezier(0.0, 0, 0.2, 1), width 0.300s cubic-bezier(0.0, 0, 0.2, 1)",
    },
  },
  media: {
    height: "100%",
    filter: "brightness(100%)",
    transition: "filter 0.300s cubic-bezier(0.0, 0, 0.2, 1)",
  },
});

const ExternalLinkCard: FC<WalletLink> = ({ display, imageUrl, navigationUrl, navigationType }) => {
  const { language } = i18n;
  const classes = useStyles();

  const target = navigationType === NavigationType.External ? "_blank" : navigationType === NavigationType.Parent ? "_parent" : "";

  return (
    <Link key={navigationUrl[language]} target={target} component={RouterLink} to={navigationUrl[language]} underline="none">
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={imageUrl[language]}></CardMedia>
        <CardContent className={classes.content}>
          <Typography className={classes.heading} variant="h5">
            {display[language]}
            <Box className={classes.icon}>
              <img src={arrowIcon} alt="Arrow Icon" height="22px" width="22px" />
            </Box>
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ExternalLinkCard;
