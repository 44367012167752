import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import { useSessionContext } from "../../contexts/SessionContext";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";
import AllocationComponent from "../../components/AllocationComponent";
import OrganizationHelpers from "../../services/Organizations/OrganizationHelpers";

type Props = {
  state: UserServiceDashboard | undefined;
};
export default function TotalAllocationComponent(props: Props) {
  const { t } = useTranslation();
  const { user, organizationHasFeature } = useSessionContext();

  const useWallet = user?.user.organizationId !== undefined && organizationHasFeature(user?.user.organizationId, OrganizationFeature.UseAccountWallet);

  let totalBalance = useWallet ? 0 : props.state?.balance ?? 0;
  let totalAllocation = 0;
  let withoutAllocation = useWallet && user.user.wallets.length > 0;

  if (useWallet) {
    for (const wallet of user.user.wallets) {
      totalAllocation += wallet.allocation;
      totalBalance += wallet.balance;

      const orgWallet = OrganizationHelpers.getOrganizationWallet(user.organizations, user.user.organizationId, wallet.organizationWalletId);
      if (!orgWallet || !orgWallet.withoutAllocation) withoutAllocation = false;
    }
  }

  return (
    <Grid container mt={9.5} mb={10} justifyContent="space-between">
      <Grid item lg={8} md={7} xs={12}>
        <Typography variant="h1">{`${t("dashboard.userPage.hello")} ${props.state?.firstName},`}</Typography>
        <Typography variant="h2" mb={4}>
          {t("dashboard.userPage.ask-label")}
        </Typography>
      </Grid>

      <AllocationComponent
        withoutAllocation={withoutAllocation}
        allocation={totalAllocation}
        balance={totalBalance}
        allocationTips={props.state?.organization.allocationTips}
      />
    </Grid>
  );
}
