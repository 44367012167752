import Organization from "./Organization";
import OrganizationWallet from "./OrganizationWallet";

export default class OrganizationHelpers {
  public static getOrganizationUserTable(organization: Organization) {
    return organization?.tables.find((t) => t.id === "UserData");
  }

  public static getOrganizationWallet(organizations: Array<Organization>, organizationId: number, orgWalletId: string): OrganizationWallet | undefined {
    const org = organizations.find((o) => o.id === organizationId);
    if (!org) return undefined;

    return org.wallets.find((w) => w.id === orgWalletId);
  }
}
