import OrganizationFeature from "../../services/Organizations/OrganizationFeature";
import { useSessionContext } from "../../contexts/SessionContext";
import Wallet from "./Wallet";

const WalletsComponent = () => {
  const { user, organizationHasFeature } = useSessionContext();

  const userOrgId = user?.user.organizationId;
  const userOrg = user?.organizations.find((o) => o.id === userOrgId);

  const useWallet = userOrgId && organizationHasFeature(userOrgId, OrganizationFeature.UseAccountWallet);

  if (!userOrg || !useWallet) return <></>;

  const filteredOrgWallets = userOrg?.wallets.filter((w) => user.user.wallets.findIndex((uw) => uw.organizationWalletId === w.id) >= 0);

  if (filteredOrgWallets.length === 0) return <></>;

  return (
    <>
      {filteredOrgWallets.map((wallet) => (
        <Wallet key={wallet.id} {...wallet} />
      ))}
    </>
  );
};

export default WalletsComponent;
