import { Box, Link, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import cashIcon from "../../assets/images/cash.svg";

import i18n from "../../services/i18n";
import OrganizationWallet from "../../services/Organizations/OrganizationWallet";
import { useSessionContext } from "../../contexts/SessionContext";
import FormatHelpers from "../../services/FormatHelpers";

const useStyles = makeStyles({
  wallet: {
    transformOrigin: "bottom",
    transition: "transform 0.300s cubic-bezier(0.0, 0, 0.2, 1)",
    "&:hover": {
      transform: "scale(0.975)",
      transition: "transform 0.300s cubic-bezier(0.4, 0, 0.2, 1)",
    }
  }
});

function Allocation({ id }: Pick<OrganizationWallet, "id">) {
  const { user } = useSessionContext();
  const lang = localStorage.getItem("language") || "en";

  const walletIndex = user?.organizations.flatMap(({ wallets }) => wallets).findIndex((wallet) => wallet.id === id) || 0;
  const wallet = user?.user.wallets[walletIndex];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "17px",
      }}
    >
      <img src={cashIcon} alt="Cash icon" width="48px" height="24.96px" />
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          gap: "5px",
        }}
      >
        <Typography fontSize={25} fontWeight={500}>
          {FormatHelpers.formatMoneyLocale(wallet?.balance, lang)}
        </Typography>
        <Typography fontSize={22} fontWeight={400} sx={{ display: { xs: "none", md: "block" } }}>
          {"/ "}
          {FormatHelpers.formatMoneyLocale(wallet?.allocation, lang)}
        </Typography>
      </Box>
    </Box>
  );
}

export default function Wallet({ display, description, backgroundColor, id, withoutAllocation }: OrganizationWallet) {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Link component={RouterLink} to={`/wallets/${id}`} underline="none">
        <Box
          className={classes.wallet}
          sx={{
            backgroundColor,
            borderRadius: "30px",
            padding: "30px 40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h3" component="h3" margin={0}>
              {display[i18n.language]}
            </Typography>
            {!withoutAllocation && <Allocation id={id} />}
          </Box>
          <Typography variant="body1" component="p" sx={{ display: { xs: "none", md: "block" } }}>
            {description?.[i18n.language]}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
}
