import WorkindApiClient from "../WorkindApiClient";
import Category from "./Category";

class MarketplaceService extends WorkindApiClient {
  async getWalletCategories(organizationId: number, organizationWalletId: string, postalCode: string | undefined): Promise<Array<Category>> {

    let path = `${this.apiUrl}/organizations/${organizationId}/marketplace/categories?organizationWalletId=${organizationWalletId}`
    if (postalCode !== undefined && postalCode.trim() !== '')
      path += `&postalCode=${postalCode}`
    const list = await this.get<Array<Category>>(path);    
    return list || [];
  }
}

const marketplaceService = new MarketplaceService();
export default marketplaceService;
