import { FC } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import theme from "../theme/main-theme";
import cashIcon from "../assets/images/cash.svg";
import { useSessionContext } from "../contexts/SessionContext";
import OrganizationFeature from "../services/Organizations/OrganizationFeature";
import FormatHelpers from "../services/FormatHelpers";
import InfoIcon from "./InfoIcon";

const useStyles = makeStyles({
  container: {
    marginRight: 15,
    padding: 20,
    backgroundColor: "#FFF",
    borderRadius: 20,
  },
  heading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    color: "#02334A",
  },
  allocation: {
    "&.MuiTypography-body1": {
      fontFamily: theme.typography.fontFamily,
      color: "#999",
    }
  },
  balance: {
    "&.MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: theme.typography.fontFamily,
      fontSize: 30,
      fontWeight: 500,
      color: "#02334A",
    }
  },
  button: {
    marginTop: 15,
    fontWeight: 500,
    letterSpacing: 0,
  }
});

type Link = {
  label: string;
  url: string;
};

type AllocationComponentProps = {
  withoutAllocation: boolean;
  allocation: number;
  balance: number;
  allocationTips?: Record<string, string>;
  link?: Link;
};

const ActionLink = ({ label, url }: Link) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} href={url} target="_parent" fullWidth>
      {label}
    </Button>
  );
}

const AllocationComponent: FC<AllocationComponentProps> = ({
  allocation,
  balance,
  link,
  withoutAllocation,
  ...props
}) => {
  const lang = localStorage.getItem("language") || "en";
  const classes = useStyles();
  const { user, organizationHasFeature } = useSessionContext();
  const { t } = useTranslation();

  let allocationTips = undefined;
  if (props.allocationTips) {
    allocationTips = (lang === "fr") ? props.allocationTips.fr : props.allocationTips.en;
  }
  allocationTips = allocationTips ? allocationTips.trim() : undefined;

  const useWallet = user?.user.organizationId && organizationHasFeature(
    user?.user.organizationId,
    OrganizationFeature.UseAccountWallet
  );

  const showAllocation = useWallet && allocation > 0;

  return (
    <Grid
      className={classes.container}
      sx={{ maxWidth: { md: "290px", xs: "100%" } }}
      lg={4} md={5} sm={12} xs={12}
      item
    >
      {!withoutAllocation && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "20px" }}>
            <Typography className={classes.heading}>
              {t("dashboard.userPage.amount-available")}
            </Typography>

            {allocationTips && (
              <Tooltip arrow title={<Box sx={{ padding: "15px", fontSize: "14px" }}>{parse(allocationTips)}</Box>}>
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            )}

            {showAllocation && (
              <Typography className={classes.allocation}>
                {`/ ${FormatHelpers.formatMoneyLocale(allocation, lang)}`}
              </Typography>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={cashIcon} alt="Cash icon" width="40" />
            <Typography className={classes.balance}>
              {FormatHelpers.formatMoneyLocale(balance, lang)}
            </Typography>
          </Box>
        </>
      )}

      {link && <ActionLink {...link} />}
    </Grid>
  );
}

export default AllocationComponent;
