import { Card, CardContent, CardMedia, Link, makeStyles, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import theme from "../../theme/main-theme";
import i18n from "../../services/i18n";
import Category from "../../services/Marketplace/Category";

const getStatus = (t: TFunction<"translation", undefined>, category: Category): string => {
  if (category.orgStatus === "soon") return t("walletPage.bientot");
  if (category.orgStatus === "new" && category.containsNewProduct) return t("walletPage.nouveaute");
  if (category.orgStatus === "new") return t("walletPage.nouveau");
  return "";
};

const useStyles = makeStyles({
  card: {
    height: 354,
    width: 325,
    marginBottom: 60,
    borderRadius: 30,
    boxShadow: "none",
    "&:hover": {
      "& .MuiCardMedia-root": {
        height: 170,
        transition: "height 0.500s cubic-bezier(0.4, 0, 0.2, 1)",
      },
      "& .MuiTypography-body1": {
        opacity: "100%",
        transition: "opacity 0.750s cubic-bezier(0.4, 0, 0.2, 1)",
      },
    },
  },
  content: {
    padding: 20,
  },
  description: {
    marginTop: 10,
    color: "#828282",
    opacity: "0%",
    transition: "opacity 0.500s cubic-bezier(0.0, 0, 0.2, 1)",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: "-1px",
    lineHeight: "26px",
  },
  icon: {
    height: 35,
    marginLeft: "1rem",
  },
  media: {
    height: 239,
    transition: "height 0.500s cubic-bezier(0.0, 0, 0.2, 1)",
  },
});

const CategoryCard: FC<Category> = ({ description, icon, image, name, slug }) => {
  const { REACT_APP_PORTAL_URL, REACT_APP_STORAGE_URL } = process.env;
  const { language } = i18n;
  const classes = useStyles();

  return (
    <Link
      component={RouterLink}
      underline="none"
      target="_parent"
      to={`${REACT_APP_PORTAL_URL}/${slug[language]}`}
    >
      <Card className={classes.card} >
        <CardMedia
          className={classes.media}
          image={`${REACT_APP_STORAGE_URL}/category/${image}`}
        />
        <CardContent className={classes.content}>
          <Typography className={classes.heading} variant="h5">
            {name[language]}
            <img
              className={classes.icon}
              src={`${REACT_APP_STORAGE_URL}/category/${icon}`}
              alt="icon"
            />
          </Typography>
          <Typography className={classes.description}>{description[language]}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default CategoryCard;
