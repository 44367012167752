import { Box, Typography } from "@mui/material";
import WalletsComponent from "../../userDashboard/components/WalletsComponent";
import { Container } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../theme/main-theme";

const useStyles = makeStyles({
  container: {
    maxWidth: 1365,
    marginTop: 145,
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
  },
  heading: {
    margin: "145px 0 3rem",
    padding: "0 1rem",
    color: theme.palette.primary.main,
    fontWeight: 500,
    letterSpacing: "-2px",
  },
});

export default function WalletsPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box pb="145px">
        <Typography className={classes.heading} variant="h1">
          {t("dashboard.userPage.wallets-label")}
        </Typography>

        <WalletsComponent />
      </Box>
    </Container>
  );
}
