import { Box, Container } from "@mui/system";
import { useEffect, useState, useMemo } from "react";

import TotalAllocationComponent from "./components/TotalAllocationComponent";
import FooterComponent from "./components/FooterComponent";
import LinksCardComponent from "./components/LinksCardComponent";
import LoadingComponent from "../components/LoadingComponent";
import { LoadingStatus } from "../contexts/LoadingStatus";
import NewsComponent from "./components/NewsComponent";
import { Notify } from "../components/notify";
import { OrderAgainComponent } from "./components/OrderAgainComponent";
import { OrdersComponent } from "./components/OrdersComponent";
import ReimbursementsComponent from "./components/ReimbursementsComponent";
import { ThemeProvider } from "@emotion/react";
import UserServiceDashboard from "../services/Users/UserDashboardProfile";
import WalletsComponent from "./components/WalletsComponent";
import theme from "../theme/main-theme";
import { useSessionContext } from "../contexts/SessionContext";
import { useTranslation } from "react-i18next";
import userService from "../services/Users/UserService";
import { Typography } from "@mui/material";
import AddressModal from "../components/AddressModal";

export default function UserDashboard() {
  const [state, setState] = useState<UserServiceDashboard | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const hasAddress = useMemo(() => {
    const address = user?.user.addresses?.[0];
    return address?.address && address?.city && address?.postalCode;
  }, [user]);

  const isMultiWallets =
    user?.user && user.user.wallets.length > 1 ? true : false;

  useEffect(() => {
    if (hasAddress) {
      return;
    }

    if (isMultiWallets) {
      return;
    }

    setShowModal(true);
  }, [hasAddress, isMultiWallets]);

  useEffect(() => {
    async function getUserDashboard() {
      try {
        setLoading(LoadingStatus.Loading);
        const result = await userService.getUserDashboard();
        if (result && user) {
          setState(result);
          setLoading(LoadingStatus.Success);
        }
      } catch {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("dashboard.error-loading"));
      }
    }
    getUserDashboard();
  }, [t, user]);

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  if (state === undefined) return <></>;

  const userOrgId = user?.user.organizationId;
  const userOrg = user?.organizations.find((o) => o.id === userOrgId);
  const filteredOrgWallets =
    user && userOrg
      ? userOrg.wallets.filter(
          (w) =>
            user.user.wallets.findIndex(
              (uw) => uw.organizationWalletId === w.id
            ) >= 0
        )
      : [];

  return (
    <ThemeProvider theme={theme}>
      <AddressModal
        open={showModal}
        onClose={handleClose}
        title={t("dashboard.adressModal.title")}
        content={t("dashboard.adressModal.content")}
      />

      <Container maxWidth="lg">
        <Box padding={{ xs: "0 4px", sm: "0 20px", md: "0 40px" }}>
          <TotalAllocationComponent state={state} />

          <NewsComponent state={state} />

          {filteredOrgWallets.length > 0 && (
            <Box mb={8}>
              <Typography variant="h3">
                {t("dashboard.userPage.wallets-label")}
              </Typography>
              <WalletsComponent />
            </Box>
          )}

          {!isMultiWallets && <LinksCardComponent />}

          <OrdersComponent state={state} />
          <OrderAgainComponent state={state} />
          <ReimbursementsComponent state={state} />
        </Box>
      </Container>
      <FooterComponent />
    </ThemeProvider>
  );
}
